  <!-- ================================= start: HERO -->
  <header class="header-nav-center no_blur" id="myNavbar">
    <div class="container">
        <!-- navbar -->
        <nav class="navbar navbar-expand-lg navbar-light px-sm-0">
            <a class="navbar-brand" routerLink="/" routerLinkActive="active">
                <img class="logo" src="assets/brand/logo/logo.png" alt="logo">
            </a>
            <button class="navbar-toggler menu ripplemenu" type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                aria-expanded="false" aria-label="Toggle navigation">
                <svg viewBox="0 0 64 48">
                    <path d="M19,15 L45,15 C70,15 58,-2 49.0177126,7 L19,37"></path>
                    <path d="M19,24 L45,24 C61.2371586,24 57,49 41,33 L32,24"></path>
                    <path d="M45,33 L19,33 C-8,33 6,-2 22,14 L45,37"></path>
                </svg>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mx-auto nav-pills">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Accueil</a>
                    </li>
                    <li class="nav-item dropdown dropdown-hover dropdown_full position-static">
                      <a class="nav-link dropdown-toggle dropdown_menu" routerLinkActive="active" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Solutions
                          <div class="icon_arrow">
                              <i class="ri-add-line"></i>
                          </div>
                      </a>
                      <div class="dropdown-menu single-drop" style="padding: 0px;margin-top: -20px;margin-left: 57px; width: 80% !important;">
                          <div class="card shadow-lg">
                            <div class="card-body">
                              <div class="container">
                                <div class="row">
                                  <div class="col-md-4">
                                    <a class="d-flex m-1" routerLink="/services/envoyer-un-sms" routerLinkActive="active">
                                      <div class="flex-shrink-0">
                                        <img src="assets/icon/chat.svg" class="mr-3" width="50px" alt="">
                                      </div>
                                      <div class="flex-grow-1 ms-3">
                                        <h6 style="font-size: 14px;">Envoi de SMS Pro</h6>
                                        <p style="font-size: 12px;" class="p-0 m-0">Envoyer vos SMS marketing et alertes facilement</p>
                                      </div>
                                    </a>
                                  </div>
                                  <div class="col-md-4">
                                    <a class="d-flex  m-1" routerLink="services/numero-mobile-virtuel">
                                      <div class="flex-shrink-0">
                                        <img src="assets/icon/vln.svg" class="mr-3" width="50px" alt="">
                                      </div>
                                      <div class="flex-grow-1 ms-3">
                                        <h6 style="font-size: 14px;">Numéro virtuel</h6>
                                        <p style="font-size: 12px;" class="p-0 m-0">Obtenez un numéro mobile virtuel pour recevoir des SMS </p>
                                      </div>
                                    </a>
                                  </div>
                                  <div class="col-md-4">
                                    <a class="d-flex  m-1" href="#!">
                                      <div class="flex-shrink-0">
                                        <img src="assets/icon/chat.svg" class="mr-3" width="50px" alt="">
                                      </div>
                                      <div class="flex-grow-1 ms-3">
                                        <h6 style="font-size: 14px;">Code d'autoritsation</h6>
                                        <p style="font-size: 12px;" class="p-0 m-0">Sécuriser vos applications avec nos codes OTP</p>
                                      </div>
                                    </a>
                                  </div>
                                  <hr class="mt-3">
                                  <div class="col-md-4">
                                    <a class="d-flex  m-1" routerLink="/services/lookup">
                                      <div class="flex-shrink-0">
                                        <img src="assets/icon/hlr.svg" class="mr-3" width="50px" alt="">
                                      </div>
                                      <div class="flex-grow-1 ms-3">
                                        <h6 style="font-size: 14px;">HLR Lookup</h6>
                                        <p style="font-size: 12px;" class="p-0 m-0">Vérifier les numéros mobiles de votre base de donnée</p>
                                      </div>
                                    </a>
                                  </div>
                                  <div class="col-md-4">
                                    <a class="d-flex  m-1" routerLink="services/location-fichier-sms">
                                      <div class="flex-shrink-0">
                                        <img src="assets/icon/target.svg" class="mr-3" width="50px" alt="">
                                      </div>
                                      <div class="flex-grow-1 ms-3">
                                        <h6 style="font-size: 14px;">Prospection SMS</h6>
                                        <p style="font-size: 12px;" class="p-0 m-0">Louer des fichiers SMS opt-in et qualifiés</p>
                                      </div>
                                    </a>
                                  </div>
                                  <div class="col-md-4">
                                    <a class="d-flex  m-1" href="#!">
                                      <div class="flex-shrink-0">
                                        <img src="assets/icon/formulaire.svg" class="mr-3" width="50px" alt="">
                                      </div>
                                      <div class="flex-grow-1 ms-3">
                                        <h6 style="font-size: 14px;">Formulaire</h6>
                                        <p style="font-size: 12px;" class="p-0 m-0">Générer en quelques clics des formulaires.</p>
                                      </div>
                                    </a>
                                  </div>
                                  <hr class="mt-3">
                                  <div class="col-md-4">
                                    <a class="d-flex  m-1" href="#!">
                                      <div class="flex-shrink-0">
                                        <img src="assets/icon/formulaire.svg" class="mr-3" width="50px" alt="">
                                      </div>
                                      <div class="flex-grow-1 ms-3">
                                        <h6 style="font-size: 14px;">Formulaire</h6>
                                        <p style="font-size: 12px;" class="p-0 m-0">Générer en quelques clics des formulaires.</p>
                                      </div>
                                    </a>
                                  </div>
                                  <div class="col-md-4">
                                    <a class="d-flex  m-1" href="#!">
                                      <div class="flex-shrink-0">
                                        <img src="assets/icon/vote.svg" class="mr-3" width="50px" alt="">
                                      </div>
                                      <div class="flex-grow-1 ms-3">
                                        <h6 style="font-size: 14px;">Vote SMS</h6>
                                        <p style="font-size: 12px;" class="p-0 m-0">Animer vos évènements avec le Vote SMS.</p>
                                      </div>
                                    </a>
                                  </div>
                                  <div class="col-md-4">
                                    <a class="d-flex  m-1" href="#!">
                                      <div class="flex-shrink-0">
                                        <img src="assets/icon/vote.svg" class="mr-3" width="50px" alt="">
                                      </div>
                                      <div class="flex-grow-1 ms-3">
                                        <h6 style="font-size: 14px;">CRM</h6>
                                        <p style="font-size: 12px;" class="p-0 m-0">Organisez et suivez toutes les interactions client</p>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="card-footer text-center">
                              <a href="#!">Voir plus</a>
                            </div>
                          </div>
                      </div>
                  </li>
                    <li class="nav-item dropdown dropdown-hover">
                      <a class="nav-link dropdown-toggle dropdown_menu" href="#" role="button"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Opportunités
                          <div class="icon_arrow">
                              <i class="ri-add-line"></i>
                          </div>
                      </a>
                      <div class="dropdown-menu single-drop sm_dropdown">
                          <ul class="dropdown_menu_nav">
                              <!-- ===== item -->
                              <li class="dropdown-hover">
                                  <a class="dropdown-item" routerLink="business/devenir-un-partenaire">
                                    Devenir partenaire
                                  </a>
                              </li>
                              <li class="dropdown-hover">
                                  <a class="dropdown-item" routerLink="business/devenir-un-revendeur-de-sms">
                                    Revendre des SMS
                                  </a>
                              </li>
                              <li class="dropdown-hover">
                                <a class="dropdown-item" routerLink="business/devenir-un-partenaire">
                                  Approteur d'affaire
                                </a>
                              </li>
                              <li class="dropdown-hover">
                                <a class="dropdown-item" routerLink="business/marque-blanche">
                                  Marque blanche
                                </a>
                              </li>
                          </ul>
                      </div>
                    </li>
                    <li class="nav-item dropdown dropdown-hover">
                      <a class="nav-link dropdown-toggle dropdown_menu" href="#" role="button"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Developpeurs
                          <div class="icon_arrow">
                              <i class="ri-add-line"></i>
                          </div>
                      </a>
                      <div class="dropdown-menu single-drop sm_dropdown">
                          <ul class="dropdown_menu_nav">
                              <!-- ===== item -->
                              <li class="dropdown-hover">
                                  <a class="dropdown-item" routerLink="/api">
                                    API
                                  </a>
                              </li>
                              <li class="dropdown-hover">
                                  <a class="dropdown-item" href="https://github.com/vavasms/">
                                    Nos SDK
                                  </a>
                              </li>
                              <li class="dropdown-hover">
                                  <a class="dropdown-item" [href]="env.app.documentation">
                                    Documentations
                                  </a>
                              </li>
                              <li class="dropdown-hover">
                                  <a class="dropdown-item" routerLink="/">
                                    Etat des services
                                  </a>
                              </li>
                          </ul>
                      </div>
                    </li>
                </ul>
                <div class="nav_account">
                    <a href="{{ env.app.login }}" class="btn btn-secondary mr-2">
                        Se connecter
                    </a>
                    <a href="{{ env.app.register }}" class="btn btn-primary">
                        Créer un compte
                    </a>
                </div>
            </div>

        </nav>
        <!-- End Navbar -->
    </div>
    <!-- end container -->
</header>
