<section class="section is_sm call2action has_style1">
  <div class="container">
      <div class="row align-items-center justify-content-between">
          <div class="col-lg-6">
              <!-- Heading -->
              <h2 class="section_title text-white mb-md-0">
                Voyons ce que vavasms peut faire pour votre entreprise.
              </h2>
          </div>
          <div class="col-lg-auto d-flex">
              <a [href]="env.app.register" style="z-index: 10;"
                  class="btn btn-white btn-lg border-0 sweep_letter sweep_top ">
                  <div class="inside_item">
                      <span>Créer un compte</span>
                  </div>
              </a>
          </div>
      </div>
  </div>

</section>
<footer class="footer has_style2 bg-darker">
  <div class="container">
      <div class="row">
          <div class="col-lg-2 col-6">
              <div class="footer-desc">
                  <div class="logo">
                      <a routerLink="/">
                          <img src="assets/brand/logo/logo_2.png" alt="Logo">
                      </a>
                  </div>
                  <p class="footer_text">
                    Le meilleur de la technologie au service de la transformation digitale des entreprises.
                  </p>
                  <div class="social_footer">
                      <a href="https://web.facebook.com/vavasms.officiel/" target="_blank"><i class="ri-facebook-fill"></i></a>
                      <a href="https://twitter.com/SmsVava" target="_blank"> <i class="ri-twitter-fill"></i></a>
                      <a href="https://github.com/vavasms/" target="_blank"> <i class="ri-github-fill"></i></a>
                  </div>
              </div>
          </div>
          <div class="col-lg-2 col-6">
              <h6 class="list-title">Services</h6>
              <ul class="list-items">
                  <li> <a routerLink="services/envoyer-un-sms">Envoi de SMS Pro</a> </li>
                  <li> <a routerLink="services/lookup">HLR Lookup</a> </li>
                  <li> <a routerLink="services/numero-mobile-virtuel">Numéro virtuel</a> </li>
                  <li> <a routerLink="/">Gateway</a> </li>
                  <li> <a routerLink="/">Vote SMS</a> </li>
              </ul>
          </div>
          <div class="col-lg-2 col-6">
            <h6 class="list-title">&nbsp;</h6>
            <ul class="list-items">
                <li> <a routerLink="/">Code d'autorisation</a> </li>
                <li> <a routerLink="/">Chatbots</a> </li>
                <li> <a routerLink="services/location-fichier-sms">Prospection SMS</a> </li>
                <li> <a routerLink="/">2 Way SMS</a> </li>
                <li> <a routerLink="/">Formulaires</a> </li>
            </ul>
          </div>
          <div class="col-lg-2 col-6">
              <h6 class="list-title">Opportunités</h6>
              <ul class="list-items">
                  <li> <a routerLink="business/devenir-un-partenaire">Devenir partenaire</a> </li>
                  <li> <a routerLink="business/devenir-un-revendeur-de-sms">Revendeur de SMS</a> </li>
                  <li> <a routerLink="business/devenir-un-partenaire">Apporteur d'affaire</a> </li>
                  <li> <a routerLink="business/marque-blanche">Marque blanche</a> </li>
              </ul>
          </div>
          <div class="col-lg-2 col-6">
            <h6 class="list-title">Ressources</h6>
            <ul class="list-items">
                <li> <a routerLink="faqs">Aide & Support</a> </li>
                <li> <a routerLink="/">Template de SMS</a> </li>
                <li> <a routerLink="api">Developpeurs</a> </li>
                <li> <a [href]="env.app.documentation" target="_blank">Documentations</a> </li>
            </ul>
          </div>
          <div class="col-lg-2 col-6">
              <h6 class="list-title">Vavasms</h6>
              <ul class="list-items">
                <li> <a routerLink="contact">Nous contacter</a> </li>
                <li> <a routerLink="a-propos">À propos de nous</a> </li>
                <li> <a routerLink="careers">Nous récurtons</a> </li>
                <li> <a routerLink="secure-text-messaging">Sécurité & confidentialité</a> </li>
                  <li> <a routerLink="terms-of-service">Conditions d'utilisations</a> </li>
              </ul>
          </div>
      </div>
  </div>
  <p class="copyright text-center "> Copyright 2017 - 2021 Vavasms. All Rights Reserved. Conçu par <a href="https://wazcodes.com">Wazcodes</a></p>
</footer>
