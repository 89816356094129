import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './views/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  { path: 'services/envoyer-un-sms', loadChildren: () => import('./views/services/send-sms/send-sms.module').then(m => m.SendSmsModule) },
  { path: 'contact', loadChildren: () => import('./views/contact/contact.module').then(m => m.ContactModule) },
  { path: 'faqs', loadChildren: () => import('./views/faqs/faqs.module').then(m => m.FaqsModule) },
  { path: 'secure-text-messaging', loadChildren: () => import('./views/security/security.module').then(m => m.SecurityModule) },
  { path: 'terms-of-service', loadChildren: () => import('./views/terms/terms.module').then(m => m.TermsModule) },
  { path: 'services/lookup', loadChildren: () => import('./views/services/lookup/lookup.module').then(m => m.LookupModule) },
  { path: 'services/location-fichier-sms', loadChildren: () => import('./views/services/prospects/prospects.module').then(m => m.ProspectsModule) },
  { path: 'business/devenir-un-partenaire', loadChildren: () => import('./views/business/partners/partners.module').then(m => m.PartnersModule) },
  { path: 'business/devenir-un-revendeur-de-sms', loadChildren: () => import('./views/business/reseller/reseller.module').then(m => m.ResellerModule) },
  { path: 'business/marque-blanche', loadChildren: () => import('./views/business/whitelabel/whitelabel.module').then(m => m.WhitelabelModule) },
  { path: 'api', loadChildren: () => import('./views/api/api.module').then(m => m.ApiModule) },
  { path: 'services/numero-mobile-virtuel', loadChildren: () => import('./views/services/vln/vln.module').then(m => m.VlnModule) },
  { path: 'careers', loadChildren: () => import('./views/careers/careers.module').then(m => m.CareersModule) },
  { path: 'a-propos', loadChildren: () => import('./views/about/about.module').then(m => m.AboutModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
